import React, { useState, useEffect, useRef } from "react";
import {
  requestGetStudents,
  requestGetSchedules,
  requestAddClasses,
  requestGetClasses,
} from "../utils/resource";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSave,
  faPlus,
  faXmark,
  faHouse,
} from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import { format, isValid } from "date-fns";
import ptBR from "date-fns/locale/pt-BR";
import "../styles/Classes.css";

const Classes = () => {
  const navigate = useNavigate();
  const [students, setStudents] = useState([]);
  const [myClasses, setMyClasses] = useState([]);

  const [schedules, setSchedules] = useState([
    { day: "Seg", classes: 1, times: [{ startTime: "", endTime: "" }] },
    { day: "Ter", classes: 1, times: [{ startTime: "", endTime: "" }] },
    { day: "Qua", classes: 1, times: [{ startTime: "", endTime: "" }] },
    { day: "Qui", classes: 1, times: [{ startTime: "", endTime: "" }] },
    { day: "Sex", classes: 1, times: [{ startTime: "", endTime: "" }] },
  ]);
  const [isAdding, setIsAdding] = useState(false);
  const [selectedDay, setSelectedDay] = useState("");
  const [selectedTime, setSelectedTime] = useState("");
  const [selectedStudents, setSelectedStudents] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredStudents, setFilteredStudents] = useState([]);
  const [showDropdown, setShowDropdown] = useState(false);
  const dropdownRef = useRef(null);

  const formatDate = (date) => {
    return isValid(date) ? format(date, "dd-MM-yyyy", { locale: ptBR }) : "";
  };

  useEffect(() => {
    if (!localStorage.getItem("_id")) {
      navigate("/");
    }
  }, [navigate]);

  useEffect(() => {
    const fetchStudents = async () => {
      const result = await requestGetStudents();
      if (result.status === "success") {
        setStudents(result.data);
      }
    };
    requestGetSchedules(localStorage.getItem("_id"), setSchedules);
    fetchStudents();
    requestGetClasses(localStorage.getItem("_id"), setMyClasses);
  }, []);

  useEffect(() => {
    setFilteredStudents(
      students.filter((student) =>
        student.name.toLowerCase().includes(searchTerm.toLowerCase())
      )
    );
  }, [searchTerm, students]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setShowDropdown(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  const handleAddStudentToSchedule = () => {
    if (!selectedDay || !selectedTime) return;

    const date = formatDate(new Date());
    const newStudents = selectedStudents.map((student) => ({
      name: student.name,
      startDate: date,
    }));

    const newClasse = {
      day: selectedDay,
      time: {
        [selectedTime]: newStudents,
      },
    };

    const existingClassIndex = myClasses.findIndex(
      (classe) => classe.day === selectedDay
    );

    let updatedClasses;

    if (existingClassIndex !== -1) {
      const existingTime = myClasses[existingClassIndex].time;

      if (existingTime[selectedTime]) {
        const newStudentIds = newStudents.filter(
          (name) => !existingTime[selectedTime].includes(name)
        );
        updatedClasses = [...myClasses];
        updatedClasses[existingClassIndex].time[selectedTime] = [
          ...existingTime[selectedTime],
          ...newStudentIds,
        ];
      } else {
        updatedClasses = [...myClasses];
        updatedClasses[existingClassIndex].time[selectedTime] = newStudents;
      }

      requestAddClasses(localStorage.getItem("_id"), updatedClasses);
      setMyClasses(updatedClasses);
    } else {
      updatedClasses = [...myClasses, newClasse];
      setMyClasses(updatedClasses);
      requestAddClasses(localStorage.getItem("_id"), updatedClasses);
    }

    setSelectedStudents([]);
    setIsAdding(false);
  };

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
    setShowDropdown(true);
  };

  const handleStudentSelect = (student) => {
    setSelectedStudents([...selectedStudents, student]);
    setSearchTerm("");
    setShowDropdown(false);
  };

  const handleRemoveStudentFromSchedule = (
    selectedDay,
    selectedTime,
    selectedName
  ) => {
    const confirmRemoval = window.confirm(
      `Você realmente deseja remover o(a) aluno(a) ${selectedName} da aula?`
    );

    if (!confirmRemoval) {
      return;
    }

    const updatedClasses = myClasses.map((classe) => {
      if (classe.day === selectedDay) {
        const updatedTime = { ...classe.time };

        if (updatedTime[selectedTime]) {
          updatedTime[selectedTime] = updatedTime[selectedTime].filter(
            (student) => student.name !== selectedName
          );

          if (updatedTime[selectedTime].length === 0) {
            delete updatedTime[selectedTime];
          }
        }

        return {
          ...classe,
          time: updatedTime,
        };
      }
      return classe;
    });

    const finalClasses = updatedClasses.filter(
      (classe) => Object.keys(classe.time).length > 0
    );

    requestAddClasses(localStorage.getItem("_id"), finalClasses);
    setMyClasses(finalClasses);
  };

  const handleCancel = () => {
    navigate("/dashboard");
  };

  const getTotalStudents = () => {
    const allStudentsSet = new Set();

    myClasses.forEach((classe) => {
      Object.values(classe.time)
        .flat()
        .forEach((student) => {
          allStudentsSet.add(student.name);
        });
    });

    return allStudentsSet.size;
  };

  return (
    <div className="classes-container">
      <main className="classes-main">
        <div className="button-bar">
          {isAdding ? (
            <>
              <FontAwesomeIcon
                icon={faSave}
                className="icon save-icon"
                onClick={handleAddStudentToSchedule}
                title="Salvar"
              />
              <FontAwesomeIcon
                icon={faXmark}
                className="icon cancel-icon"
                onClick={() => setIsAdding(false)}
                title="Cancelar"
              />
            </>
          ) : (
            <>
              <FontAwesomeIcon
                icon={faPlus}
                className="icon add-icon"
                onClick={() => setIsAdding(true)}
                title="Adicionar Alunos ao Horário"
              />
              <FontAwesomeIcon
                icon={faHouse}
                className="icon cancel-icon"
                onClick={handleCancel}
                title="Cancelar"
              />
            </>
          )}
        </div>

        {isAdding && (
          <div className="schedule-form">
            <p className="times-label">Horários</p>
            <div className="schedule-grid">
              <label>
                <select
                  onChange={(e) => {
                    setSelectedDay(e.target.value);
                    setSelectedTime("");
                  }}
                  value={selectedDay}
                >
                  <option value="">Escolha um dia</option>
                  {schedules.map((schedule) => (
                    <option key={schedule.day} value={schedule.day}>
                      {schedule.day}
                    </option>
                  ))}
                </select>
              </label>
              <label>
                <select
                  onChange={(e) => setSelectedTime(e.target.value)}
                  value={selectedTime}
                >
                  <option value="">Escolha um horário</option>
                  {selectedDay &&
                    schedules
                      .find((schedule) => schedule.day === selectedDay)
                      ?.times.map((timeSlot, index) => (
                        <option
                          key={index}
                          value={`${timeSlot.startTime}-${timeSlot.endTime}`}
                        >
                          {`${timeSlot.startTime} - ${timeSlot.endTime}`}
                        </option>
                      ))}
                </select>
              </label>
            </div>

            <p className="student-label">Alunos</p>
            <div className="students-search" ref={dropdownRef}>
              <input
                type="text"
                placeholder="Buscar por nome"
                value={searchTerm}
                onChange={handleSearch}
              />
              {showDropdown && filteredStudents.length > 0 && (
                <div className="students-dropdown">
                  {filteredStudents.map((student) => (
                    <div
                      key={student.id}
                      className="student-item"
                      onClick={() => handleStudentSelect(student)}
                    >
                      {student.name}
                    </div>
                  ))}
                </div>
              )}
            </div>

            <ul className="selected-students">
              {selectedStudents.map((student) => (
                <li key={student.id}>
                  {student.name}
                  <FontAwesomeIcon
                    icon={faXmark}
                    className="remove-student-icon"
                    title="Remover Aluno"
                    onClick={() =>
                      setSelectedStudents(
                        selectedStudents.filter((s) => s.id !== student.id)
                      )
                    }
                  />
                </li>
              ))}
            </ul>
          </div>
        )}

        <div className="students-total">
          Total de alunos: {getTotalStudents()}
        </div>

        {myClasses.map((classe) => {
          const hasStudents = Object.values(classe.time).some(
            (students) => students.length > 0
          );

          if (hasStudents) {
            return (
              <div className="schedule-item" key={classe.day}>
                <h3>{classe.day}</h3>
                {Object.entries(classe.time)
                  .sort((a, b) => {
                    const startTimeA = a[0].split("-")[0];
                    const startTimeB = b[0].split("-")[0];
                    return startTimeA.localeCompare(startTimeB);
                  })
                  .map(([time, students]) => (
                    <div key={time}>
                      <h4>
                        {time}
                        {students.length >= 4 && (
                          <span className="full-message">(Máx)</span>
                        )}
                      </h4>
                      <ul>
                        {students.map((student) => (
                          <li key={student.name}>
                            {student.name}
                            <FontAwesomeIcon
                              icon={faXmark}
                              className="remove-student-icon"
                              title="Remover Aluno"
                              onClick={() =>
                                handleRemoveStudentFromSchedule(
                                  classe.day,
                                  time,
                                  student.name
                                )
                              }
                            />
                          </li>
                        ))}
                      </ul>
                    </div>
                  ))}
              </div>
            );
          }

          return null;
        })}
      </main>
    </div>
  );
};

export default Classes;
